#chat-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    & div:first-child {
      display: flex;
      flex-direction: column;
    }
  }

  &.sharing-mode {
    .video-share-container {
      position: absolute !important;
      width: 100%;
      max-width: 200px;
      bottom: 2rem;
      right: 2rem;
      z-index: 5 !important;
    }
  }

  &.full-screen {
    max-width: unset;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

    .video-share-container {
      position: absolute;
      width: 100%;
      max-width: 200px;
      bottom: 2rem;
      right: 2rem;
      z-index: 5;
    }
  }

  #video_chat {
    position: relative;
    min-height: 400px;

    .video-share-container,
    .screen-share-container {
      position: absolute;
      bottom: 0;
      z-index: 5;

      @media screen and (max-width: 767px) {
        position: relative;
        order: 2;

        .OTPublisherContainer {
          margin: 0;
          width: 100% !important;
        }
      }
    }

    .OTSubscriberContainer {
      width: 100% !important;
      height: 1px !important;
      padding-bottom: 56.2%;
      z-index: 1;
      bottom: 0;
    }

    .screen-subscriber-container {
      position: relative;

      div:nth-child(2) {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 767px) {
        order: 1;
      }
    }

    .chat-banner {
      position: absolute;
      height: 4rem;
      background: rgba(255, 255, 255, 0.5);
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 2;

      @media screen and (max-width: 767px) {
        position: relative;
        height: auto;
      }
      @media screen and (max-width: 767px) {
        order: 3;
      }
    }
  }

  .action-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 0.5rem;

    --border-width: 1px;
  }
}

#rate-video-chat {
  .header {
    position: relative;
    min-height: 200px;
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .userImage {
      &.client {
        z-index: 2;
        position: absolute;
        left: calc(50% + 45px);
        transform: translateX(-50%);
      }

      &.expert {
        position: absolute;
        z-index: 1;
        left: calc(50% - 45px);
        transform: translateX(-50%);
      }
    }

    .message {
      position: absolute;
      left: 50%;
      bottom: -0.5rem;
      transform: translateX(-50%);
      z-index: 10;
      width: 100%;
      font-size: 1.5rem;
      border: none;
    }
  }

  textarea {
    width: 90%;
    margin: auto;
    height: 100px;
    border: 1px solid var(--ion-color-secondary);
    border-radius: 6px;
    padding: 0.7rem;
    background-color: rgba(var(--ion-color-secondary-rgb), 0.1);
  }
}

// Calification and comments
$stars: 5;
$star-size: 30px;
$star-spacing: 20px;
$star-on-color: var(--ion-color-warning);
$star-off-color: rgba(var(--ion-color-warning-rgb), 0.15);

.rating {
  width: ($star-size * $stars) + ($star-spacing * ($stars - 1));
  height: $star-size;
  margin: auto;
  padding: 5px 10px;
  text-align: center;

  label {
    float: right;
    position: relative;
    width: $star-size;
    height: $star-size;
    cursor: pointer;

    &:not(:first-of-type) {
      margin-right: ($star-spacing * 0.5);
    }

    &::before {
      content: "\2605";
      font-size: $star-size + 2px;
      color: $star-off-color;
      line-height: 1;
    }
  }

  input {
    display: none;
  }

  input:checked ~ label::before,
  &:not(:checked) > label:hover::before,
  &:not(:checked) > label:hover ~ label::before {
    color: $star-on-color;
  }
}

#new-comments-form {
  & > * {
    flex: 1;
    text-align: center;
    min-width: 200px;
  }

  .rating {
    max-width: 250px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  textarea {
    width: 90%;
    margin: auto;
    height: 100px;
    border: 1px solid var(--ion-color-secondary);
    border-radius: 6px;
    padding: 0.7rem;
    background-color: rgba(var(--ion-color-secondary-rgb), 0.1);
  }
}

.error-message-styled {
  font-size: 0.7rem;
  color: var(--ion-color-danger);
}
