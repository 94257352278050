#paymentTable {
  #appSlider {
    box-shadow: 0 3px 6px 0 rgba(var(--ion-color-dark-rgb), 0.16);
    margin-bottom: 2.5rem;
    max-height: 400px;
    overflow: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th {
      padding: 0.7rem;
      font-weight: 500;
    }

    tbody {
      tr {
        padding: 0.5rem;
        border: 1px solid rgba(var(--ion-color-secondary-rgb), 0.2);

        &:nth-child(even) {
          background-color: rgba(var(--ion-color-secondary-rgb), 0.08);
        }
      }

      td {
        text-align: center;
        margin-bottom: 0.5rem;

        p {
          margin: 0;
        }

        &.userCell {
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          ion-img {
            width: 90px;
            height: 90px;
            margin: 0 0.5rem !important;
          }

          p {
            font-weight: 400;
          }
        }
      }
    }

    @media (max-width: 767px) {
      thead {
        display: none;
      }

      tbody,
      tr,
      td {
        display: block;
        width: 100%;
      }

      tbody {
        tr {
          margin-bottom: 1rem;
        }

        td {
          &:first-child {
            margin-bottom: 1rem;
          }

          &:last-child {
            margin-top: 1rem;
          }

          &:not(:first-child):not(:last-child) {
            text-align: right;
            padding-left: 50%;
            position: relative;
          }

          &:not(:first-child):not(:last-child)::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            font-weight: 400;
            text-align: left;
          }
        }
      }
    }
  }
}
