.login-wrapper {
  max-width: 1200px;
  margin: auto;
}

ion-col.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ion-col.descriptionLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.modal-wrapper .ion-page {
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    max-width: 200px;
  }

  ion-button {
    &#close-btn {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 1000;
    }

    &.customPointer {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.svg-header {
  width: auto;
  height: auto;
  max-width: 130px;
  display: block;
}

.svg-logo {
  width: auto;
  height: auto;
  max-width: 450px;
  display: block;
}

.PrivacyAnchor,
.termsAnchor {
  font-weight: 300;
  position: absolute;
  bottom: 100px;
  text-align: center;
  cursor: pointer;
}

.login {
  position: relative;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  svg {
    max-width: 500px;
    width: 80%;
    height: auto;
    margin: 2rem auto;
  }

  .header {
    position: relative;
    z-index: 3;
    text-align: center;
    height: auto !important;
    line-height: 1.5 !important;

    .subheader {
      width: 70%;
      min-width: 250px;
      max-width: 300px;
      margin: 30px auto;
      font-weight: 300;
    }

    .selectorsContainer {
      list-style: none;

      .selector {
        cursor: pointer;
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: rgba(var(--ion-color-primary-rgb), 0.5);
        margin: 5px;
        border-radius: 100%;

        &.active {
          background-color: rgba(var(--ion-color-primary-rgb), 0.8);
        }
      }
    }
  }

  .termsAnchor {
    font-weight: 400;

    &:hover {
      color: rgba(var(--ion-color-secondary-rgb), 0.5);
    }
  }
}

.descriptionLogin {
  h1 {
    font-size: 1.8rem !important;
    margin: 1rem 0;
    font-weight: 500;
    text-align: center;
    max-width: 400px;
  }

  p {
    max-width: 350px;
    font-size: 0.8rem;
    font-weight: 300;
    margin: 1rem auto;
    margin-bottom: 1.5rem;
    line-height: 1.2rem;
    text-align: left;
  }

  .login-form {
    max-width: 300px;
    margin: 1rem auto;

    ion-checkbox.keeploged-checkbox {
      margin: 5px 10px 5px 0;
      width: 20px !important;
      height: 20px !important;
    }

    .keeploged-checkbox-message {
      font-size: 0.8rem;
    }

    .pass-toggle {
      position: absolute;
      top: 15px;
      right: 10px;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }

  .form-divider {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  .PrivacyAnchor {
    font-weight: 400;

    &:hover {
      color: rgba(var(--ion-color-secondary-rgb), 0.5);
    }
  }
}

.cookiesContainer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  padding: 2rem;
  background: rgba(var(--ion-color-primary-rgb), 0.8);
  color: var(--ion-color-primary-contrast);
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 30;
  transition: opacity 1s ease-in-out;

  &.hidden {
    opacity: 0;
    z-index: -5;
  }

  p {
    margin: 0;

    span {
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }
}

.collapsableContainer {
  visibility: visible;
  max-height: 1000px;
  transition: all 0.2s ease-in-out;

  &.collapsed {
    max-height: 0.1px;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
}
