.stepComponent {
  .description {
    margin: 4rem 2rem;
    line-height: 1.5rem;
    text-align: justify;
  }

  .servicesContainer {
    .serviceStatus {
      position: absolute;
      left: 1rem;
      top: 1rem;
      width: 1rem;
      height: 1rem;

      &.serviceActive {
        background-color: var(--ion-color-secondary);
        border-color: var(--ion-color-secondary);
      }
    }

    .svgService {
      width: 100%;
      height: 100%;
      padding: 1rem;
    }

    img.img-fluid {
      margin: auto;
      display: block;
    }

    h4 {
      font-size: 1.3rem;
      text-align: center;
    }
  }

  .custom-select {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    width: 100%;
    padding: 0.3rem;
    border-radius: 0.4rem;

    option {
      background-color: var(--ion-color-primary-contrast);
      color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-secondary);
    }

    &::-ms-expand {
      display: none;
    }

    &:disabled {
      opacity: 0.3;
    }

    &.invalid {
      background-color: rgba(var(--ion-color-tertiary), 0.8);
    }
  }

  .myLanguages {
    display: inline-block;
    width: 100%;
  }
}
