@import "./variables";

html {
  scroll-behavior: smooth;
}

body {
  --ion-default-font: "Roboto", sans-serif;

  font-weight: 300;
  color: var(--ion-color-primary);
  overflow-x: hidden;

  #application-header,
  #application-footer {
    max-height: 200px;
    transition: all 0.25s ease-in-out;
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  @media screen and (min-width: 768px) {
    &.full_chat {
      transition: all 0.25s ease-in-out;

      & > * {
        transition: all 0.25s ease-in-out;
      }

      ion-menu {
        transition: all 0.25s ease-in-out;
        max-width: 1px !important;
        display: none;

        & > * {
          display: none;
        }
      }

      ion-router-outlet {
        width: 100%;
        flex: 1;
      }

      #application-header,
      #application-footer {
        transition: all 0.25s ease-in-out;
        max-height: 1px;
        overflow: hidden;
      }

      main.inner-scroll {
        padding: 0;
      }

      #chat-container {
        max-width: unset;
      }
    }
  }
}

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.bigModal {
  @media screen and (min-width: 768px) {
    --width: 90%;
    --max-width: 1200px;
  }
}

.borderModal {
  @media screen and (min-width: 768px) {
    --border-radius: 1rem;
  }
}

ion-button.social-button {
  max-width: 300px;
  width: 100%;
  margin: 0.5rem auto;
}

.smallModal {
  @media screen and (min-width: 768px) {
    --max-width: 400px;
  }
}

.smallModalHeight {
  @media screen and (min-width: 768px) {
    --height: auto;
    --min-height: 400px;
  }
}

#main-menu.menu-content-open {
  pointer-events: all !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.termsList {
  list-style: none;

  li {
    margin-bottom: 0.5rem;
    margin-top: 0.3rem;
  }
}

.userProfileImage {
  position: relative;
  width: 130px;
  height: 130px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid var(--ion-color-light);

  &::part(image) {
    height: auto !important;
  }
}

.selectAvatarIcon {
  position: absolute;
  padding: 0.2rem;
  right: 5%;
  bottom: 5%;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
  background-color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-light);
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: var(--ion-color-secondary);
    border: 1px solid var(--ion-color-primary);
  }
}

.rounded-circle {
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--ion-color-light);
}

.image-big {
  width: 200px;
  height: 200px;
}

.section-title {
  position: relative;
  margin-bottom: 1rem;

  h2 {
    font-weight: 500;
    display: inline;
    position: relative;
    padding: 0 1rem;
    background-color: var(--ion-background-color, #fff);
    z-index: 1;
  }

  span.headerSubtitle {
    background-color: var(--ion-color-primary-contrast);
    display: block;
    position: relative;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
  }

  span.title-border {
    position: absolute;
    width: 100%;
    bottom: 2px;
    left: 0;
    display: inline-block;
    border-bottom: 2px solid var(--ion-color-secondary);
    z-index: 0;
  }

  &.text-center {
    span.title-border {
      bottom: 50%;
      transform: translateY(-50%);
    }
  }
}

.infoCard {
  .card-title {
    display: flex;
    font-size: 1.3rem;

    .svgIcon {
      svg {
        path {
          fill: var(--ion-color-primary);
          transition: all 0.15s ease-in-out;
        }
      }

      &.star {
        svg path {
          fill: var(--ion-color-tertiary);
        }
      }

      &:hover {
        &.editFunction svg path,
        &.deleteFunction svg path {
          fill: var(--ion-color-secondary);
        }
      }
    }
  }

  h2,
  h3 {
    text-align: left;
    font-size: 2rem;
    color: var(--ion-color-primary);
  }

  h5 {
    font-size: 1rem;
    font-weight: 500;
  }

  p {
    margin: 0;

    &.company {
      font-size: 2rem;
    }

    &.timeLapse {
      font-size: 1.7rem;
      font-weight: bold;
      color: var(--ion-color-secondary);
    }

    &.field {
      font-weight: bold;
      line-height: 0.9rem;
    }

    &.website {
      font-size: 1rem;
      line-height: 0.9rem;
    }
  }
}

.industrieCard {
  margin-bottom: 1rem;

  .card-title {
    display: flex;
    font-size: 1rem;
  }

  h2,
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h5 {
    font-size: 1rem;
    font-weight: 500;
    font-weight: bold;
  }

  p {
    margin: 0;
    margin-bottom: 0.5rem;

    &.company {
      font-size: 1.3rem;
    }

    &.timeLapse {
      font-size: 1.5rem;
      margin-top: 0.6rem;
      font-weight: bold;
      color: var(--ion-color-secondary);
    }

    &.field {
      font-weight: bold;
      line-height: 0.9rem;
    }

    &.website {
      font-size: 1rem;
      line-height: 0.9rem;
    }
  }
}

.languagesContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: bold;

  ion-label {
    margin-bottom: 8px;
  }
}

.careerHighlights {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;

  .card-title {
    display: flex;
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--ion-color-primary);
  }

  .body-description {
    font-weight: 400;
    margin: 0;
    font-size: 0.85rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--ion-color-light), 0.7);
    z-index: -1;
  }

  ion-card {
    h2 {
      font-weight: bold;
    }
  }
}

.closeBtn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
  width: 40px;
  height: 40px;

  --border-radius: 50%;

  &.smallMdlBtn {
    right: 0;
    top: 0.5rem;
  }
}

.message {
  margin-bottom: 0;
  margin-top: 1rem;
  padding: 10px 30px;
  border-bottom: 1px solid var(--ion-color-secondary);
  font-size: 1.2rem;
  color: var(--ion-dark-secondary);
  position: relative;

  span {
    text-align: center;
    display: block;

    &.label {
      position: absolute;
      color: var(--ion-color-secondary);
      left: 15px;
      top: -5px;
      font-size: 0.85rem;

      &.no-float {
        top: unset;
      }
    }
  }
}

.questions_answers {
  .section-title {
    margin: 0;
    margin-bottom: 1rem;

    h2 {
      font-size: 1.5rem;
      color: var(--ion-color-secondary);
    }
  }
}

ion-icon {
  &.closeIcon {
    float: right;
  }

  &.serviceStatus {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
  }

  &.serviceIcon {
    width: 80px;
    height: 80px;
    color: rgba(var(--ion-color-secondary-rgb), 0.3);
  }
}

.active {
  ion-icon {
    &.serviceIcon {
      color: var(--ion-color-secondary);
    }
  }
}

// Expert modal styles
#ExpertsInfoModal {
  ion-grid,
  ion-row,
  ion-col {
    height: auto !important;
  }

  #close-btn {
    ion-icon {
      color: var(--ion-color-light) !important;
    }
  }

  .profileBanner {
    width: 100%;
    height: 160px;
    position: relative;
    margin-bottom: 7rem;
    border-radius: 5px;
    background-image: url("/assets/images/dashboardMenu.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    ion-avatar,
    ion-img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -100px;
    }
  }

  #accordion {
    border: 1px solid var(--ion-color-secondary);
    border-radius: 10px;
    margin-top: 2rem;
    overflow: hidden;

    ion-card {
      border-radius: 0;

      ion-card-header {
        border: none;
        border-radius: 0;
        padding: 0;
      }

      ion-card-title {
        color: var(--ion-color-primary);
        text-transform: uppercase;
        width: 100%;
        text-align: left;
        position: relative;
        padding: 0.75rem 1.25rem;
        background-color: rgba(var(--ion-color-secondary-rgb), 0.2);
      }

      ion-card-content {
        &.collapsable {
          max-height: 10000px;
          transition: max-height 0.25s ease-out;

          &.hide {
            padding: 0;
            max-height: 0;
            overflow: hidden;
          }
        }
      }

      &.serviceCard {
        ion-card-header {
          background-color: rgba(var(--ion-color-medium-rgb), 0.2);
          border-radius: 10px 10px 0 0;
        }

        ion-card-content {
          background-color: rgba(var(--ion-color-medium-rgb), 0.2);
          border-radius: 0 0 10px 10px;

          h4 {
            margin: 0;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      ion-card {
        ion-icon {
          &.serviceIcon {
            width: 50px;
            height: 50px;
          }
        }

        &.serviceCard {
          ion-card-content {
            h4 {
              font-size: $h5_font-size !important;
            }

            p {
              font-size: $label_fz !important;
            }
          }
        }
      }
    }
  }
}

form {
  .form-error {
    margin-top: 0.4rem;
    display: block;
    font-size: 0.8rem;
  }

  .form__group {
    position: relative;
    margin-bottom: 0.5rem;
  }

  .form__label {
    pointer-events: initial;
    position: absolute;
    padding: 0 15px;
    top: -5px;
    left: 10px;
    display: block;
    transition: 0.2s;
    font-size: 0.9rem;
    color: var(--ion-color-primary);
    background-color: var(--ion-background-color, #fff);
  }

  .form__field:placeholder-shown ~ .form__label {
    cursor: text;
    top: 17px;
  }

  .form__field[readonly] {
    background-color: rgba(var(--ion-color-medium-rgb), 0.2);
    border-radius: 5px !important;

    &.noDisabled {
      background-color: transparent;
    }
  }

  .form__field:focus ~ .form__label {
    position: absolute;
    top: -5px;
    display: block;
    transition: 0.2s;
    font-size: 0.8rem;
    color: var(--ion-color-secondary);
  }

  .form__group.disabled_field .form__label {
    background-color: var(--ion-color-medium);
    color: var(--ion-color-medium-contrast) !important;
  }

  .form__field {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--ion-color-primary);
    padding: 15px 15px 5px 15px;
    background: transparent;
    transition: border-color 0.2s;

    &.invalid {
      background-color: rgba(var(--ion-color-warning-rgb), 0.2);
      border-color: var(--ion-color-danger);
    }

    &.invalid:not(:focus) ~ .form__label {
      background-color: transparent;
      color: var(--ion-color-danger);
      font-weight: 400;
    }
  }

  .form__field::placeholder {
    color: transparent;
  }

  .form__field:focus {
    padding-bottom: 6px;
    border-color: var(--ion-color-secondary);
  }
}

.react-tel-input .form-control {
  background-color: var(--ion-background-color, #fff) !important;
}

.__react_component_tooltip {
  max-width: 250px;
}

.controlled-width {
  max-width: 1200px;
  margin: 0 auto;
}

.clickable {
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.special-select {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0.3rem;
  border-radius: 0.4rem;

  option {
    background-color: var(--ion-color-secondary-contrast);
    color: var(--ion-color-secondary);
    border: 1px solid var(--ion-color-secondary);
  }

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    opacity: 0.3;
  }

  &.invalid {
    background-color: rgba(var(--ion-color-tertiary), 0.8);
  }
}

.expert-badges {
  padding: 0.5rem;
  margin: 0.3rem;
  pointer-events: none;
}

.button-circular {
  width: 30px;
  height: 30px;
  margin: 0 0.5rem;

  --border-radius: 50%;
}

.collapsable-container {
  transition: all 0.25s ease-in-out;

  &.expand {
    max-height: 10000px;
  }

  &.collapse {
    max-height: 0;
    overflow: hidden;
  }
}

.border-secondary {
  border: 1px solid rgba(var(--ion-color-secondary-rgb), 0.9);
  border-radius: 10px;
}

.communityModalContainer {
  text-align: left;

  h5,
  h6 {
    text-align: center;
  }

  ol {
    margin-left: 2rem;
    padding: 1rem;
    border: 1px solid var(--ion-color-primary);
    border-radius: 8px;
  }

  li {
    margin-left: 1rem;
    padding-left: 0.5rem;
  }
}

.confirm_push_information {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  padding: 1rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  max-width: 400px;
  min-width: 250px;
  margin: 2rem auto 0;
}

.offer-price {
  display: block;
  font-size: 0.6rem;
  color: var(--ion-color-warning);
  span {
    text-decoration: line-through;
  }
}

.offer-price-community {
  font-size: 1rem;
  display: block;
  color: var(--ion-color-warning);
  text-shadow: 2px 2px 10px var(--ion-color-primary-contrast);
  span {
    text-decoration: line-through;
  }
}
