.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(#000, 0.4);
  z-index: 999990000000;

  &.hidden {
    display: none;
    z-index: -10000;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.cube-folding {
  width: 110px;
  height: 110px;
  display: inline-block;
  font-size: 0;
}

.cube-folding span {
  position: relative;
  width: 40px;
  height: 40px;
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  display: inline-block;
}

.cube-folding span::before {
  content: '';
  background-image: url('/assets/images/logo/logo_01.png');
  background-size: 97%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 95%;
  height: 95%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -moz-animation: folding 2.5s infinite linear both;
  -webkit-animation: folding 2.5s infinite linear both;
  animation: folding 2.5s infinite linear both;
}

.cube-folding .leaf2 {
  -moz-transform: rotateZ(0deg) scale(1.1);
  -ms-transform: rotateZ(0deg) scale(1.1);
  -webkit-transform: rotateZ(0deg) scale(1.1);
  transform: rotateZ(0deg) scale(1.1);
}

.cube-folding .leaf2::before {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-image: url('/assets/images/logo/logo_02.png');
}

.cube-folding .leaf3 {
  -moz-transform: rotateZ(0deg) scale(1.1);
  -ms-transform: rotateZ(0deg) scale(1.1);
  -webkit-transform: rotateZ(0deg) scale(1.1);
  transform: rotateZ(0deg) scale(1.1);
}

.cube-folding .leaf3::before {
  -moz-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  background-image: url('/assets/images/logo/logo_03.png');
}

.cube-folding .leaf4 {
  -moz-transform: rotateZ(0deg) scale(1.1);
  -ms-transform: rotateZ(0deg) scale(1.1);
  -webkit-transform: rotateZ(0deg) scale(1.1);
  transform: rotateZ(0deg) scale(1.1);
}

.cube-folding .leaf4::before {
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  background-image: url('/assets/images/logo/logo_04.png');
}

@-moz-keyframes folding {
  0%,
  10% {
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes folding {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes folding {
  0%,
  10% {
    -moz-transform: perspective(140px) rotateX(-180deg);
    -ms-transform: perspective(140px) rotateX(-180deg);
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -moz-transform: perspective(140px) rotateX(0deg);
    -ms-transform: perspective(140px) rotateX(0deg);
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -moz-transform: perspective(140px) rotateY(180deg);
    -ms-transform: perspective(140px) rotateY(180deg);
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.cube-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  text-align: center;
}

.cube-wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: auto;
  width: 90px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-filter: blur(2px);
  filter: blur(2px);
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  z-index: 1;
  -moz-animation: shadow 0.5s ease infinite alternate;
  -webkit-animation: shadow 0.5s ease infinite alternate;
  animation: shadow 0.5s ease infinite alternate;
}

.cube-wrapper .loading {
  font-size: 12px;
  letter-spacing: 0.1em;
  display: block;
  color: white;
  position: relative;
  top: 25px;
  z-index: 2;
  -moz-animation: text 0.5s ease infinite alternate;
  -webkit-animation: text 0.5s ease infinite alternate;
  animation: text 0.5s ease infinite alternate;
}

@-moz-keyframes text {
  100% {
    top: 35px;
  }
}

@-webkit-keyframes text {
  100% {
    top: 35px;
  }
}

@keyframes text {
  100% {
    top: 35px;
  }
}

@-moz-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}

@-webkit-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}

@keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
