#dashboard {
  .btn-donations {
    border-radius: 10px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
    padding: 1rem 2rem;
    margin: auto;
    display: block;

    .header,
    .subheader {
      text-transform: uppercase;
      display: block;

      &:first-of-type {
        margin-bottom: 0;
        margin-top: 1rem;
        font-family: 'Raleway', sans-serif;
        font-size: 1.15rem;
        font-weight: 300;
        position: relative;

        &::before {
          content: '';
          width: 80%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -0.3rem;
          border-bottom: 1px solid var(--ion-color-light);
          transition: all 0.25s ease-in-out;
        }
      }

      &:last-of-type {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }
  }

  .filtersContainer {
    background-color: var(--ion-color-primary);
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;

    ion-chip {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      h2 {
        max-width: 100%;
        flex: 0.9;
        margin: 0.5rem;
        display: inline-block;
        color: var(--ion-color-light);
        font-weight: 300;
      }

      button.btn {
        border-radius: 20px;
      }
    }

    @media (max-width: 767px) {
      ion-chip {
        margin: 0.8rem auto;
        flex-direction: column;
        justify-content: center;
        height: auto;

        h2 {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .ExpertsResultsContainer {
    ion-slides {
      ion-card-title {
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rate {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          margin-right: 10px;
          font-size: 0.8rem;

          ion-icon {
            margin-left: 8px;
            font-size: 0.9rem;
          }
        }
      }

      .buttonAction {
        min-width: 100%;
        max-width: 150px;
        border-radius: 40px;
        transition: all 0.15s ease-in-out;
      }
    }
  }

  #profile-completition-card {
    background: rgba(var(--ion-color-medium-rgb), 0.9);

    ion-card-content.profile-completition-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    ion-avatar.profile-completition-avatar {
      width: 95px;
      height: 95px;
    }

    .profile-completition-body {
      max-width: 70%;

      p {
        font-size: 1.2rem;
        color: var(--ion-color-medium-contrast);
      }

      ion-button {
        margin-top: 1rem;
        float: right;
      }
    }

    @media (max-width: 767px) {
      ion-card-content.profile-completition-content {
        justify-content: center;

        ion-avatar.profile-completition-avatar {
          width: 110px;
          height: 110px;
          margin: 1rem auto;
        }

        .profile-completition-body {
          text-align: center;

          ion-button {
            margin: 1rem auto;
            float: none;
          }
        }
      }
    }
  }

  #comments-container {
    min-height: 170px;

    ion-avatar {
      margin-left: 1rem;
      border: 1px solid var(--ion-color-primary);
      box-shadow:
        0 4px 8px 0 rgba(var(--ion-color-dark-rgb), 0.2),
        0 6px 20px 0 rgba(var(--ion-color-dark-rgb), 0.19);
    }

    .comment-content {
      .comment-reviewer {
        font-size: 1.12rem;
        padding-left: 1rem;
      }

      .comment-date {
        float: right;
        color: var(--ion-color-tertiary);
        font-size: 0.7rem;
        font-weight: 400;
      }

      .user-comment {
        padding-left: 1rem;
        margin: 0 4rem 0 0;
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    @media (max-width: 767px) {
      min-height: 250px;
    }
  }

  #request-slider {
    ion-card.request-card {
      width: 100%;
      box-shadow: none;
      transition: box-shadow 0.15s ease-in-out;

      &:hover {
        box-shadow:
          0 4px 8px 0 rgba(var(--ion-color-dark-rgb), 0.2),
          0 6px 20px 0 rgba(var(--ion-color-dark-rgb), 0.19);
      }

      .image-container {
        position: relative;

        .user-image {
          width: 100%;
          cursor: pointer;
        }
  
        .image-card-name {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 0.3rem;
          left: 0;
          padding: 0.2rem 0.5rem;
          z-index: 10;
          color: var(--ion-color-light);
          font-weight: normal;
          font-size: 1rem;
          background-color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
        }
      }

      ion-card-header {
        cursor: pointer;
      }

      ion-card-title.request-card-title {
        position: relative;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        span {
          color: var(--ion-color-tertiary);
        }
      }

      .line_clamp {
        cursor: pointer;
      }
    }
  }

  ion-button.reschedule-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    --border-width: 1px;

    ion-icon.reschedule-icon {
      font-size: 1.2rem;
    }
  }

  #expert_profile_earnings {
    position: relative;
    background-color: rgba(var(--ion-color-dark-rgb), 0.25);
    border-radius: 20px;
    padding: 2rem 2rem;

    .description {
      font-size: 3.4rem;
      line-height: 3rem;
      font-weight: bold;
      max-width: calc(100% - 150px);

      .message {
        font-size: 1.5rem;
        display: block;
        border: none;
        margin: 0;
        padding: 0;
        color: var(--ion-color-primary);
      }
    }

    ion-button.earnings_button {
      float: right;
    }

    @media (max-width: 767px) {
      .description {
        font-size: 2.5rem;
        margin-right: 0;
        max-width: unset;
      }

      ion-button.earnings_button {
        float: none;
        margin: 1rem auto;
      }
    }
  }
}

#donationsContainer {
  .donationsImages {
    margin: 1rem auto;
    margin-bottom: 2rem;
    border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.5);
    max-width: 400px;
    padding: 1rem 0;
    border-radius: 10px;

    .images {
      margin: 10px 0;

      img {
        max-width: 130px;
        width: 100%;
        height: auto;
        margin: 0 5px;
      }
    }
  }

  .donationsText {
    font-weight: 400;
    line-height: 1.5;

    a {
      color: var(--ion-color-secondary);

      &:hover {
        color: var(--ion-color-tertiary);
      }
    }
  }
}

.cancelled {
  display: block;
  top: -0.65rem;
  color: var(--ion-color-secondary);
  font-weight: bold;
  font-size: 0.7rem;
  text-decoration: line-through;
}
