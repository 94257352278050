.ErrorModal .modal-wrapper {
  ion-icon.ErrorModal--Icon {
    color: var(--ion-color-danger);
    font-size: 5rem;
    line-height: 5rem;
  }

  h3 {
    font-size: 2rem;
    color: var(--ion-color-danger);
  }

  p {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}
