.fixed-position {
  justify-content: space-around;
  align-items: center;
  width: 100%;

  .react-timekeeper {
    display: inline !important;
    position: relative !important;
    z-index: 5;
    top: unset;
    left: unset;
    transform: translate(0, 0);
  }

  .react-calendar {
    border: none;
  }
}

.custom-time-keeper,
.react-timekeeper {
  display: block !important;
  position: absolute !important;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rbc-toolbar > * {
  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    margin: 0.3rem auto;
    text-align: center;
  }
}
