.rich-text-content {
  margin: 2rem auto;
}

.blog-view {
  .blogHero {
    position: relative;
    width: auto;
    margin: 0 -30px;
    z-index: 0;
    min-height: 600px;
    background-size: cover;
    background-position: center center;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      background:
        linear-gradient(
          rgba(var(--ion-color-dark-rgb), 0.6),
          rgba(var(--ion-color-dark-rgb), 0.6)
        );
    }

    .blogData {
      z-index: 100;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      text-align: center;

      .categories {
        display: inline-block;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 1.5rem;

        .categorieName {
          display: inline;
          color: var(--ion-color-primary-contrast);
          padding: 0.3rem;
          position: relative;
          margin-right: 0.5rem;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 3px solid var(--ion-color-secondary);
          }
        }
      }

      .serviceSvg {
        background-color: rgba(var(--ion-color-light-rgb), 0.8);
        padding: 1rem;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
      }

      h1 {
        position: relative;
        color: var(--ion-color-primary-contrast);
        z-index: 10;
        text-align: center;
      }
    }
  }

  .blog-body {
    position: relative;
    z-index: 100;
    max-width: 1200px;
    width: 80%;
    margin: auto;
    margin-top: -80px;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 20px;

    .rich-text-content {
      img:not(.image-media) {
        max-width: 50%;
        margin: 0 auto;
        display: block;
      }
    }

    img.image-media {
      max-width: 600px;
      margin: 2rem auto;
      display: block;
    }

    iframe,
    video.video-media {
      margin: 2rem auto;
      display: block;
    }
  }

  .author {
    text-align: center;
    margin: 0 -30px;
    padding: 4rem 0;
    margin-top: 1rem;
    background-color: var(--ion-color-primary-contrast);

    .title {
      max-width: 400px;
      margin: auto;
      position: relative;
      margin-bottom: 1rem;

      h5 {
        font-weight: 200;
        display: inline;
        position: relative;
        background-color: var(--ion-color-primary-contrast);
        z-index: 1;
        padding: 0 0.7rem;
      }

      span.title-border {
        position: absolute;
        width: 100%;
        bottom: 50%;
        transform: translateY(-50%);
        left: 0;
        display: inline-block;
        border-bottom: 1px solid var(--ion-color-secondary);
        z-index: 0;
      }
    }

    .author-image {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      background-size: 80%;
      border: 1px solid var(--ion-color-primary);
      background-position: center;
      background-repeat: no-repeat;
      margin: 2rem auto;
    }

    .author-name {
      font-size: 1rem;
      font-weight: normal;
    }
  }
}

.blog-container {
  .blogHero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0 -30px;
    z-index: 0;
    min-height: 400px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      background:
        linear-gradient(
          rgba(var(--ion-color-dark-rgb), 0.6),
          rgba(var(--ion-color-dark-rgb), 0.6)
        );
    }

    .title {
      position: relative;
      color: var(--ion-color-primary-contrast);
      z-index: 10;
    }
  }

  .noBlogHero {
    position: relative;
    width: 100%;
    min-height: 400px;

    .title {
      position: relative;
      color: var(--ion-color-dark);
      z-index: 10;
    }
  }

  .service-cards {
    margin: 0 auto;
    width: 95%;
    max-width: 1200px;

    .service {
      background-color: rgba(var(--ion-color-secondary-rgb), 0.25);
      color: var(--ion-color-primary);
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: rgba(var(--ion-color-secondary-rgb), 0.15);
        transform: translate(0, -1rem);

        &::before {
          background-color: rgba(var(--ion-color-dark-rgb), 0.2);
        }
      }

      & > * {
        z-index: 10;
        position: relative;
        color: var(--ion-color-primary);
        text-decoration: none;
      }

      a {
        text-decoration: none;
        display: block;
      }

      .service-image {
        position: relative;
        display: block;
        width: auto;
        height: 100px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 1rem auto;
      }

      h3 {
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  .cards {
    margin: 0 auto;
    width: 95%;
    max-width: 1200px;

    .blog-cards {
      background-color: var(--ion-background-color, #fff);
      text-decoration: none;
      color: var(--ion-color-primary);
      position: relative;
      border-radius: 5px;
      transition: all 0.25s ease-in-out !important;

      & > * {
        z-index: 10;
        position: relative;
        color: var(--ion-color-primary);
        text-decoration: none;
      }

      .footer {
        border-top: 1px solid var(--ion-color-primary);
        padding-top: 0.5rem;
        font-weight: normal;
      }

      &:hover {
        background-color: rgba(var(--ion-color-secondary-rgb), 0.6);
        transform: translate(0, -1rem);

        & > * {
          color: var(--ion-background-color, #fff);
        }

        .footer {
          border-color: var(--ion-color-primary);
        }

        &::before {
          background-color: rgba(var(--ion-color-dark-rgb), 0.2);
        }
      }

      .excerpt {
        font-weight: normal;
        margin-bottom: 1rem;

        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0;
        }
      }
    }
  }
}
