#appoinmentsList {
  box-shadow: 0 3px 6px 0 rgba(var(--ion-color-dark-rgb), 0.16);
  margin-bottom: 2.5rem;

  #appSlider {
    max-height: 500px;
    overflow: scroll;

    .appoinmentCard {
      .informationIcon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      ion-text {
        &.appoinmentHead {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      ion-row {
        &.questions {
          max-height: 300px;
          overflow: scroll;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .appoinmentCard {
        .informationIcon {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 0;
          top: 0;
        }

        ion-text {
          &.appoinmentHead {
            flex-direction: column;

            span {
              display: block;
              margin-bottom: 0.7rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.update-appointment-modal {
  ion-icon.successModal--Icon {
    color: var(--ion-color-success);
    font-size: 5rem;
    line-height: 5rem;
  }

  .main-message {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}

ion-icon.ErrorModal--Icon {
  color: var(--ion-color-danger);
  font-size: 5rem;
  line-height: 5rem;
}

#expert-appointments-update {
  .rbc-toolbar > * {
    margin: 0.3rem auto;

    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .rbc-event {
    height: 80px;
  }
}
