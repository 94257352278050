ion-menu {
  --background: url("/assets/images/dashboardMenu.png");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  ion-header,
  ion-content {
    --background: transparent;

    background-image: transparent;
  }

  &.hidden {
    display: none !important;
  }

  ion-list {
    background: transparent !important;
  }

  ion-item {
    --border-width: 0;
    --border-color: transparent;
    --background: transparent;

    a {
      ion-icon {
        color: var(--ion-color-primary-contrast);
        font-size: 1.15rem;
        line-height: 1.3rem;
        margin-right: 0.8rem;
      }

      line-height: 1.3rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      text-decoration: none;
      color: var(--ion-color-primary-contrast);
      transition: all 0.15s ease-in-out;

      &.active,
      &:hover {
        ion-icon {
          color: var(--ion-color-secondary);
        }

        color: var(--ion-color-secondary);
      }
    }
  }

  .userProfileInfo {
    p {
      color: var(--ion-color-light);
      margin: 1.5rem 0 0;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 150px !important;
  }
}
