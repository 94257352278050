#settingsPage {
  ion-card-content {
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    color: var(--ion-color-primary);

    p {
      font-size: 1rem;
      font-weight: 500;
      position: relative;
      padding-left: 1.7rem;
    }

    ion-icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1.3rem;
      height: 1.3rem;
    }

    &:hover {
      color: var(--ion-color-secondary);
    }
  }
}
