.successModal .modal-wrapper {
  ion-icon.successModal--Icon {
    color: var(--ion-color-success);
    font-size: 5rem;
    line-height: 5rem;
  }

  p {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}
