#application-header {
  ion-img.svg-logo {
    width: auto;
    height: auto;
    max-width: 130px;
    display: block;

    --background: rgba(var(--ion-color-primary), 0.7);

    img {
      padding: 0.5rem;
    }
  }
}
