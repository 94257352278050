#main-search-filters {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .nav-prev {
    left: -13px;
  }

  .nav-next {
    right: -13px;
  }

  .nav-prev,
  .nav-next {
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    color: var(--ion-color-primary);
    transition: all 0.25s ease-in-out;

    &:hover {
      color: var(--ion-color-secondary);
    }
  }

  .filter-card {
    cursor: pointer;
    margin: 0;
    border-radius: 10px;
    transition: all 0.25s ease-in-out;

    ion-card-header {
      padding-bottom: 5px;

      ion-card-title {
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
          color: var(--ion-color-secondary);
        }
      }
    }

    ion-card-content {
      line-height: normal;

      ion-label {
        display: block;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
          color: var(--ion-color-secondary);
        }
      }
    }

    &:hover {
      -webkit-box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
      box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
    }
  }
}
