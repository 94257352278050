
ion-icon.submitLogo {
  width: 100%;
  height: 130px;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

#social-media-share {
  margin-top: 2rem;

  h4 {
    text-transform: uppercase;
    text-align: center;
  }

  .buttons {
    margin-bottom: 1rem;
    text-align: center;

    .react-share__ShareButton {
      max-width: 35px;
      max-height: 35px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      overflow: hidden;
      margin-right: 1em;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
