.lastPaymentInformation {
  border: 1px solid rgba(var(--ion-color-secondary-rgb), 0.5);
  padding: 1rem;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  h5 {
    font-size: 1rem !important;
    font-weight: 500;
  }

  p {
    margin-bottom: 0.5rem;
    color: var(--ion-color-secondary-rgb);
    font-weight: 500;

    span {
      color: var(--ion-color-secondary);
      font-weight: 400;
    }
  }
}

ion-icon.stripeLogo {
  width: 120px;
  height: 30px;
  margin: auto;
  display: block;
}
