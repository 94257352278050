#dashboard {
  .profileBanner {
    width: 100%;
    height: 160px;
    position: relative;
    margin-bottom: 7rem;
    border-radius: 5px;
    background-image: url("/assets/images/dashboardMenu.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .avatarContainer {
      position: absolute;
      bottom: 0;

    }
    
    .avatar {
      position: relative;
    }

    ion-avatar {
      width: 150px;
      height: 150px;
      position: relative;
      left: 2rem;
      bottom: -75px;
      border: 3px solid var(--ion-color-primary-contrast);
    }
  }

  .request-services {
    .services-card-icon {
      width: 100px;
      height: 100px;
      margin: auto;
    }

    .service-title {
      font-size: 1.3rem !important;
      height: 2.6rem;
    }
  }
}

#contact-form {
  .form__field {
    border-color: var(--ion-color-secondary);
  }

  .form__label {
    font-weight: 400;
    color: var(--ion-color-secondary);
  }
}

#resumeButton {
  background-color: var(--ion-color-primary);
  padding: 15px 20px;
  border-radius: 40px;
  border: none;
  margin: 1rem 0;

  ion-icon {
    width: 20px;
    height: 20px;
    color: var(--ion-color-primary-contrast);
  }

  .fileNameContainer,
  .resumeStatus {
    p,
    ion-text {
      margin: 0;
      color: var(--ion-color-primary-contrast);
    }
  }
}
