input,
select,
textarea,
button {
  outline: none !important;
  box-shadow: none !important;
  caret-color: var(--ion-color-secondary);

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

a {
  cursor: pointer;

  &:active,
  &:focus,
  &:visited {
    outline: none !important;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
