#paymentModal {
  ion-col {
    height: auto;
  }

  .closeBtn {
    ion-icon {
      color: white;
      width: 20px;
    }
  }

  .header {
    position: relative;
    background-color: var(--ion-color-primary);
    padding: 20px 10px;
    margin-bottom: 1rem;

    h4 {
      color: var(--ion-color-light);
      text-align: center;
    }
  }

  .userImage {
    position: relative;
    padding: 0 30px;
    margin-bottom: 2rem;

    p {
      display: inline-block;
      margin-left: 1rem;
      margin-bottom: 0;

      span {
        display: block;
        font-weight: bold;
      }
    }
  }
}
