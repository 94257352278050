#lang-card {
  max-width: 300px;
  position: absolute;
  z-index: 100;
  right: 0;
  top: -200%;
  opacity: 0;
  max-height: 1px;
  transition: all 0.25s ease-in-out;

  &.show {
    top: 0;
    max-height: 300px;
    opacity: 1;
  }

  ion-icon.flag {
    width: 40px;
  }

  ion-item {
    cursor: pointer;

    &:hover {
      background: rgba(var(--ion-color-secondary-rgb), 0.5);
    }
  }
}
