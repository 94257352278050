#billing-form {
  .form__group {
    margin-bottom: 1.3rem;
    font-weight: 500;
  }

  .form__field {
    text-align: left;
    border-color: var(--ion-color-secondary);
  }

  .form__label {
    font-weight: 400;
    color: var(--ion-color-secondary);
  }

  .form__field:placeholder-shown ~ .form__label {
    top: 12px;
  }
}
