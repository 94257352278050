.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25%;
  height: 75%;
}

.controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .actions {
      flex: 1 1 0%;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  ion-range {
      max-width: 400px;
      flex: 1 1 0% !important;
  }
}
